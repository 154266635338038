<template>
  <div>
    <TopMenuSubheadline :title="title" />
      <div class="app-content-box bg-gray wrapper">
      <div class="container mt-4">
        <div class="input-spinner mt-4">
          <div class="top-heading-medium text-center">nazwij swój plan czytelniczy</div>
          <div class="row align-items-center">
            <div class="col-12">
              <div class="input-container mb-0 input-wrapper">
                <input type="text" class="form-input w-100"
                :class="{'input-validation': isTitleValid}"
                placeholder="Mój plan czytelniczy" v-model="trainingPlanTitle">
              </div>
            </div>
          </div>
          <div class="top-heading-medium text-center">ile czasu chcesz poświęcić na czytanie podczas jednej sesji?</div>
          <div class="input-spinners normal ">
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-hour">
              <VerticalSlider :options="sessionHoursArray" @active-index="setSessionHours" />
              <div class="input-spinner-label">h</div>
            </div>
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="sessionMinutesArray" @active-index="setSessionMinutes" />
              <div class="input-spinner-label">min.</div>
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
               <VerticalSlider :options="sessionTimesArray" @active-index="setSessionTimes" />
            </div>
          </div>
        </div>
        <hr class="section-spacer section-spacer-black">
        <div class="input-spinner mt-4">
          <div class="top-heading-medium text-center">jak długo chcesz czytać <br/>z trenerem?</div>
          <div class="input-spinners normal ">
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-time">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="trainingDaysArray" @active-index="setTrainingDays" />
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="trainingTimesArray" @active-index="setTrainingTimes" />
            </div>
          </div>
        </div>
        <div>
            <button class="btn-black-full w-100 mt-5" @click="validInput">Dalej</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import VerticalSlider from '../components/VerticalSlider'
export default {
  name: 'TrainingPlanTime',
  components: {
    TopMenuSubheadline,
    VerticalSlider
  },
  data () {
    return {
      title: 'Edytuj plan czasu',
      trainingPlanTitle: '',
      hoursPerSession: 0,
      minPerSession: 0,
      timePerSession: 'dziennie',
      daysWithTrainer: 1,
      timeWithTrainer: 'tygodnie',
      url: '',
      index: '',
      isTitleValid: false,
      sessionHoursArray: ['0', '1', '2', '3', '4', '5'],
      sessionMinutesArray: ['00', '10', '20', '30', '40', '50'],
      sessionTimesArray: ['dziennie', 'tygodniowo', 'miesięcznie'],
      trainingDaysArray: [1, 2, 3, 4, 5, 6],
      trainingTimesArray: ['tygodnie', 'miesięcy']
    }
  },
  methods: {
    submit () {
      const perSessionUnit = this.timePerSession === 'dziennie' ? 'day' : 'week'
      const fullTimeUnit = this.timeWithTrainer === 'tygodnie' ? 'week' : 'month'
      const sessionTime = (parseFloat(this.hoursPerSession) * 60) + parseFloat(this.minPerSession)
      const getData = `name=${this.trainingPlanTitle}&type=time&full_time_unit=${fullTimeUnit}&full_time=${this.daysWithTrainer}&time_per=${sessionTime}&time_per_unit=${perSessionUnit}`
      this.$https('/plans', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(({ data }) => {
        this.$router.push({ name: 'TrainingPlanSelectBooks', params: { plan_id: data.response.id } })
      })
        .catch(error => {
          console.log('', error)
        })
    },
    validInput () {
      let checkInput = this.trainingPlanTitle
      checkInput = checkInput.replace(/\s/g, '')
      if (checkInput === '') {
        this.isTitleValid = true
      } else {
        this.isTitleValid = false
        this.submit()
      }
    },
    setSessionHours (index) {
      this.hoursPerSession = this.sessionHoursArray[index]
    },
    setSessionMinutes (index) {
      this.minPerSession = this.sessionMinutesArray[index]
    },
    setSessionTimes (index) {
      this.timePerSession = this.sessionTimesArray[index]
    },
    setTrainingDays (index) {
      this.daysWithTrainer = this.trainingDaysArray[index]
    },
    setTrainingTimes (index) {
      this.timeWithTrainer = this.trainingTimesArray[index]
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    padding-top: 100px;
    padding-bottom: 70px;
  }

  .input {
    &-wrapper {
      padding-bottom: 20px;
    }
    &-validation {
      border: 1px solid red;
    }
  }
</style>
